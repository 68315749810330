<template>
  <v-container class="fill-height">
    <v-row>
      <v-col class="text-center" cols="12">
        <h1 class="mb-5">Keytools</h1>

        <template v-if="user">
          <v-row align="center" justify="center">
            <v-col cols="auto">
              <v-btn to="/rotator">Keyword Rotator</v-btn>
            </v-col>
            <v-col cols="auto" v-if="false">
              <v-btn to="/settings">Settings</v-btn>
            </v-col>
            <v-col cols="auto">
              <v-btn to="/logout">Logout</v-btn>
            </v-col>
          </v-row>
        </template>

        <template v-else>
          <v-row align="center" justify="center">
            <v-col cols="auto">
              <v-btn to="/signin">Sign In</v-btn>

            </v-col>
            <v-col cols="auto">
              <v-btn to="/signup">Sign Up</v-btn>
            </v-col>
          </v-row>
        </template>

      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import { mapState } from 'vuex';

  export default {
    name: 'Home',

    computed: {
      ...mapState(['user'])
    }
  }
</script>
